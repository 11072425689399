<template>
  <v-theme-provider :dark="dark">
    <div>

      <base-title
        size="text-h3"
        space="4"
        title="Alton Carter"
        weight="regular"
      />

      <base-body>
        
      </base-body>

      <base-btn
        class="mb-12"
        color="white"
        :to="{name: 'Biography'}"
        outlined
      >
        More Info
      </base-btn>
    </div>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'BaseInfo',

    props: {
      title: String,
      dark: Boolean,
    },

    data: () => ({
      business: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: 'P.O. Box 2564<br>Oklahoma City, OK  73101',
        },
      ],
    }),
  }
</script>
