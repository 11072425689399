import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        primary: '#F4511E',
        secondary: '#050b1f',
        accent: '#204165',
      },
    },
  },
});
