// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/Base.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/views/home/Index.vue'),
        },
        {
          path: 'family',
          name: 'Family',
          component: () => import('@/views/family/Index.vue'),
          meta: { src: require('@/assets/family_bg.png') }
        },
        {
          path: 'interests-lifestyle',
          name: 'Interests & Lifestyle',
          component: () => import('@/views/interests/Index.vue'),
          meta: { src: 'https://imgur.com/uZK7eYP.png' }
        },
        {
          path: 'biography',
          name: 'Biography',
          component: () => import('@/views/biography/Index.vue'),
          meta: { src: require('@/assets/biography_bg.png') }
        },
        {
          path: 'blog',
          name: 'Blog',
          component: () => import('@/views/blog/Index.vue'),
          meta: { src: 'https://pbs.twimg.com/media/Dr7NLz9UwAAHni6?format=jpg&name=large' }
        },
        {
          path: 'blog/:slug',
          name: 'Blog Entry',
          component: () => import('@/views/blog/Article.vue'),
          meta: { src: 'https://pbs.twimg.com/media/Dr7NLz9UwAAHni6?format=jpg&name=large' },
        },
        {
          path: 'contact',
          name: 'Contact',
          component: () => import('@/views/contact/Index.vue'),
          meta: { src: 'https://pbs.twimg.com/media/D6E7UETWAAINPno?format=jpg' }
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router
